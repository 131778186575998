import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const Home = () => import(/* webpackChunkName: "home" */ '../views/Home.vue');
const Page = () => import(/* webpackChunkName: "page" */ '../views/Page.vue');
// const Splash = () =>
// 	import(/* webpackChunkName: "splash" */ '../views/Splash.vue');

const routes = [
	{
		path: '/home',
		name: 'Home',
		component: Home,
	},
	{
		path: '/:slug',
		name: 'Page',
		component: Page,
		meta: {
			transition: 'overlay-right-full',
		},
	},
	{
		path: '*',
		redirect: '/home',
	},
	{
		path: '/',
		redirect: '/home',
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.NODE_ENV === 'production' ? '/game-day/' : '/',
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

export default router;
