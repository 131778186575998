<template>
	<div id="app">
		<main-header v-if="$route.name != 'Splash'" />

		<vue-page-transition name="fade">
			<router-view />
		</vue-page-transition>
	</div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue';

export default {
	components: {
		MainHeader,
	},

	created() {
		this.$root.get_data();
	},
};
</script>

<style lang="scss">
@import '@/styles/app';
</style>
