import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VuePageTransition from 'vue-page-transition';
import axios from 'axios';

Vue.config.productionTip = false;
Vue.use(require('vue-moment'));
Vue.use(VuePageTransition);

new Vue({
	data: {
		game: null,
		pages: [],
		timeline: [],
		date: Date.now(),
	},
	methods: {
		get_data() {
			return new Promise(resolve => {
				axios
					.get(
						`https://s3.us-east-2.amazonaws.com/sidearm.nextgen.sites/baylorbears.com/game-day/json/data.json?v=${this.date}`,
					)
					.then(response => {
						if (!response) return;

						resolve(response);

						this.game = response.data.game;
						this.pages = response.data.pages;
						this.timeline = response.data.timeline;
					});
			});
		},
	},
	router,
	render: h => h(App),
}).$mount('#app');

router.afterEach(to => {
	window.gtag('config', 'UA-121497480-14', {
		page_path: `/game-day${to.path}`,
	});
});
