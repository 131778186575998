<template>
	<div
		class="main-header d-flex justify-content-between align-items-center px-1 x-large-px-4"
	>
		<button
			aria-label="Toggle menu"
			class="main-header__toggle"
			:class="{ 'is-toggled': menu_toggled }"
			@click="toggle_menu"
			type="button"
		>
			<div></div>
		</button>

		<div class="main-header__logo absolute">
			<router-link to="/home" :tabindex="menu_toggled ? '-1' : '0'">
				<svg
					id="Layer_1"
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 180 180"
				>
					<path
						class="st0"
						fill="#124734"
						d="M83.166 96.439v-4.602l7.507.002V74.038H57.859v17.801h7.506v4.6h17.801z"
					/>
					<path
						class="st0"
						fill="#124734"
						d="M155.905 91.837v-17.8H123.09v17.8h7.51v51.9c0 1.326-.154 1.825-.751 2.422l-3.667 3.665c-.612.614-1.065.755-2.425.755H90.01c-1.358 0-1.812-.141-2.429-.758l-3.66-3.659c-.6-.6-.755-1.1-.755-2.425v-26.709h8.044c5.443 0 9.039 0 13.3-4.255l7.079-7.08c4.267-4.26 4.266-7.782 4.266-13.114V73.292c0-5.329 0-8.849-4.251-13.093l-2.448-2.449 2.433-2.435c4.267-4.259 4.266-7.781 4.266-13.114v-7.943c0-5.329 0-8.85-4.251-13.094l-7.104-7.109c-4.247-4.241-7.842-4.241-13.286-4.241H24.1v17.8h8.579v71.613H24.1v17.8h41.266v26.713c0 5.443 0 9.038 4.255 13.3l7.081 7.079c4.257 4.265 7.7 4.265 12.922 4.265h34.521c5.214 0 8.659 0 12.9-4.25l7.11-7.107c4.242-4.248 4.242-7.843 4.242-13.286V95.856l-8.9-4.017zM91.21 27.615c1.327 0 1.826.155 2.422.751l3.668 3.667c.614.613.754 1.066.754 2.425v7.549c0 1.359-.141 1.813-.758 2.428L93.635 48.1c-.6.6-1.1.754-2.425.754H50.475V31.633l-8.9-4.018zM50.475 99.227V66.649H91.21c1.328 0 1.826.155 2.422.751l3.668 3.668c.614.612.754 1.065.754 2.424v18.892c0 1.359-.141 1.813-.758 2.428l-3.659 3.661a2.659 2.659 0 01-2.211.751zm95.213 62.69a3.768 3.768 0 11-3.778 3.769 3.761 3.761 0 013.778-3.769m0 8.269a4.5 4.5 0 10-4.512-4.5 4.475 4.475 0 004.512 4.5m.779-4.272a1.285 1.285 0 001.35-1.385c0-1.065-.824-1.34-1.739-1.34h-2.13v5.061h.731v-2.336h.939l1.3 2.336h.836zm-.527-1.992c.607 0 1.146.048 1.146.687 0 .688-.94.572-1.626.572h-.781v-1.259z"
					/>
				</svg>
			</router-link>
		</div>

		<a
			href="https://baylorbears.evenue.net/cgi-bin/ncommerce3/SEGetGroupList?groupCode=FB&linkID=baylor&shopperContext=&caller=&appCode=&_ga=2.27212605.1016804871.1597169452-1202998114.1597072518"
			class="main-header__tickets text-white"
			target="_blank"
			rel="noopener"
			:class="{ 'dropdown-open': menu_toggled }"
		>
			<svg
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				x="0"
				y="0"
				viewBox="0 0 1080 1080"
				height="35"
				xml:space="preserve"
			>
				<path
					class="st0"
					d="M711.5 953h-83.2c-17.4 0-33.3-10.8-42.7-28.8-10.2-19.5-26.9-31.2-44.8-31.2s-34.6 11.7-44.8 31.2c-9.4 18-25.4 28.8-42.7 28.8h-84.8c-27.7 0-50.2-27.3-50.2-60.8V187.8c0-33.5 22.5-60.8 50.2-60.8h84c17.4 0 33.3 10.8 42.7 28.8 10.2 19.5 26.9 31.2 44.8 31.2s34.6-11.7 44.8-31.2c9.4-18 25.4-28.8 42.7-28.8h84c27.7 0 50.2 27.3 50.2 60.8v704.4c0 33.5-22.5 60.8-50.2 60.8zm-170.7-80c25.4 0 48.8 15.7 62.5 42 5.9 11.3 15.2 18 25 18h83.2c16.7 0 30.2-18.3 30.2-40.8V187.8c0-22.5-13.6-40.8-30.2-40.8h-84c-9.8 0-19.1 6.7-25 18-13.7 26.3-37.1 42-62.5 42s-48.8-15.7-62.5-42c-5.9-11.3-15.2-18-25-18h-84c-16.7 0-30.2 18.3-30.2 40.8v704.4c0 22.5 13.6 40.8 30.2 40.8h84.8c9.8 0 19.1-6.7 25-18 13.7-26.3 37.1-42 62.5-42z"
				/>
				<path
					class="st0"
					d="M711.5 963h-83.2c-21.2 0-40.5-12.8-51.6-34.1-8.4-16.2-21.9-25.8-35.9-25.8s-27.5 9.7-35.9 25.8c-11.1 21.4-30.4 34.1-51.6 34.1h-84.8c-33.2 0-60.2-31.8-60.2-70.8V187.8c0-39 27-70.8 60.2-70.8h84c21.2 0 40.5 12.8 51.6 34.1C512.5 167.3 526 177 540 177s27.5-9.7 35.9-25.8c11.1-21.4 30.4-34.1 51.6-34.1h84c33.2 0 60.2 31.8 60.2 70.8v704.4c0 38.9-27 70.7-60.2 70.7zm-110.1-33.1c7.4 8.4 16.9 13.1 26.9 13.1h83.2c22.2 0 40.2-22.8 40.2-50.8V187.8v704.4c0 28-18 50.8-40.2 50.8h-83.2c-10 0-19.5-4.8-26.9-13.1zm-273.1-37.7c0 28 18 50.8 40.2 50.8h84.8c8 0 15.6-3 22.1-8.5-6.5 5.4-14.2 8.5-22.1 8.5h-84.8c-22.2 0-40.2-22.8-40.2-50.8zm147.2 42.3zm65.3-71.5c29.2 0 55.9 17.7 71.4 47.3 4.1 7.9 10.1 12.6 16.1 12.6h83.2c11 0 20.2-14.1 20.2-30.8V187.8c0-16.7-9.3-30.8-20.2-30.8h-84c-6 0-12 4.7-16.1 12.6-15.5 29.7-42.2 47.4-71.4 47.4-29.2 0-55.9-17.7-71.4-47.3-4.1-7.9-10.1-12.6-16.1-12.6h-84c-11 0-20.2 14.1-20.2 30.8v704.4c0 16.7 9.3 30.8 20.2 30.8h84.8c6 0 12-4.7 16.1-12.6 15.5-29.8 42.2-47.5 71.4-47.5zm0 20h1.2-1.7.5zm86.7-746c-11.3 0-21.9 6-29.6 16.5 7.7-10.5 18.3-16.5 29.6-16.5zm-175 0c10.8 0 21 5.5 28.5 15.1-7.5-9.6-17.7-15.1-28.5-15.1z"
				/>
				<g>
					<path class="st0" d="M385 290.8h76.7v20H385z" />
					<path
						class="st0"
						d="M471.7 320.8H375v-40h96.7v40zm-76.7-20h56.7H395zM501.7 290.8h76.7v20h-76.7z"
					/>
					<path
						class="st0"
						d="M588.3 320.8h-96.7v-40h96.7v40zm-76.6-20h56.7-56.7z"
					/>
					<g>
						<path class="st0" d="M618.3 290.8H695v20h-76.7z" />
						<path
							class="st0"
							d="M705 320.8h-96.7v-40H705v40zm-76.6-20H685h-56.6z"
						/>
					</g>
					<g>
						<path class="st0" d="M385 769.2h76.7v20H385z" />
						<path
							class="st0"
							d="M471.7 799.2H375v-40h96.7v40zm-76.7-20h56.7H395zM501.7 769.2h76.7v20h-76.7z"
						/>
						<path
							class="st0"
							d="M588.3 799.2h-96.7v-40h96.7v40zm-76.6-20h56.7-56.7z"
						/>
					</g>
					<g>
						<path class="st0" d="M618.3 769.2H695v20h-76.7z" />
						<path
							class="st0"
							d="M705 799.2h-96.7v-40H705v40zm-76.6-20H685h-56.6z"
						/>
					</g>
				</g>
			</svg>
		</a>

		<div
			class="main-header__off-canvas d-flex flex-column justify-content-center align-items-center"
			:class="{ 'is-open': menu_toggled }"
		>
			<div class="main-header__off-canvas-inner text-center">
				<div class="main-header__label text-white">Menu</div>
				<ul class="main-header__level-1 reset-list">
					<li
						class="main-header__item--level-1"
						v-for="item in items"
						:class="$route.path === item.href ? 'is-active' : ''"
						:key="`item-${item.id}`"
					>
						<router-link
							class="main-header__url--level-1"
							:tabindex="menu_toggled ? '0' : '-1'"
							:target="item.open_in_new_window ? '_blank' : ''"
							:to="item.href"
							v-if="!item.external_link"
							>{{ item.title }}</router-link
						>
						<a
							class="main-header__url--level-1"
							:href="item.href"
							:tabindex="menu_toggled ? '0' : '-1'"
							:target="item.open_in_new_window ? '_blank' : ''"
							v-if="item.external_link"
							>{{ item.title }}</a
						>
					</li>
				</ul>
				<ul
					class="main-header__social-list d-flex align-items-center justify-content-center reset-list"
				>
					<li
						class="main-header__social-list-item"
						v-for="icon in icons"
						:key="`icon-${icon.id}`"
					>
						<a
							:aria-label="icon.label"
							:href="icon.href"
							class="main-header__social-list-url"
							target="_blank"
							:tabindex="menu_toggled ? '0' : '-1'"
						>
							<span
								aria-hidden="true"
								:class="`sidearm-icon ${icon.icon}`"
							></span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MainHeader',

	data() {
		return {
			items: [
				{
					id: 1,
					external_link: false,
					href: '/home',
					open_in_new_window: false,
					title: 'Gameday Home',
				},
				{
					id: 2,
					external_link: false,
					href: '/activities',
					open_in_new_window: false,
					title: 'Activities',
				},
				{
					id: 3,
					external_link: false,
					href: '/parking-and-directions',
					open_in_new_window: false,
					title: 'Parking & Directions',
				},
				{
					id: 4,
					external_link: false,
					href: '/policies-and-safety',
					open_in_new_window: false,
					title: 'Policies & Safety',
				},
				{
					id: 5,
					external_link: false,
					href: '/student-gameday',
					open_in_new_window: false,
					title: 'Student Gameday',
				},
				{
					id: 6,
					external_link: false,
					href: '/tickets',
					open_in_new_window: false,
					title: 'Tickets',
				},
				{
					id: 7,
					external_link: false,
					href: '/tailgating',
					open_in_new_window: false,
					title: 'Tailgating',
				},
				{
					id: 8,
					external_link: false,
					href: '/watching-at-home',
					open_in_new_window: false,
					title: 'Watching at Home',
				},
				{
					id: 9,
					external_link: true,
					href: 'https://baylorbears.com/sports/football/schedule',
					open_in_new_window: true,
					title: 'Football Schedule',
				},
			],
			menu_toggled: false,
			icons: [
				{
					id: 1,
					href: 'https://twitter.com/BUFootball',
					icon: 'sf-twitter',
					label: 'Go to Baylor Football Twitter',
				},
				{
					id: 2,
					href: 'https://www.facebook.com/BUFootball',
					icon: 'sf-facebook',
					label: 'Go to Baylor Football Facebook',
				},
				{
					id: 3,
					href: 'https://www.instagram.com/bufootball',
					icon: 'sf-instagram',
					label: 'Go to Baylor Football Instagram',
				},
			],
		};
	},

	methods: {
		handle_scroll() {
			const nav = document.getElementsByClassName('main-header')[0];

			nav.classList.add('is-scrolling');
		},

		toggle_menu() {
			this.menu_toggled = !this.menu_toggled;
		},
	},

	created() {
		window.addEventListener('scroll', this.handle_scroll);
	},

	beforeDestroy() {
		window.removeEventListener('scroll', this.handle_scroll);
	},

	watch: {
		$route() {
			this.menu_toggled = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.main-header {
	background: $color-white;
	border-bottom: 1px solid $color-border;
	height: 92px;
	position: fixed;
	top: 0;
	left: 0;
	transition: 0.25s;
	width: 100%;
	z-index: 100;

	body.home-page & {
		background: transparent;
		border-color: transparent;

		&.is-scrolling {
			background: $color-white;
			border-color: $color-border;
		}
	}

	body:not(.home-page):not(.common-page) & {
		display: none;
	}

	&__toggle {
		@include base-reset;
		animation: fade-in-down 0.5s 0.3s ease forwards;
		height: 100%;
		opacity: 0;
		position: relative;
		width: 60px;
		transform: translateY(-100%);
		z-index: 100;
		&:hover,
		&:focus {
			cursor: pointer;
		}
		&.is-toggled {
			> div {
				background: $color-white !important;
				transform: rotate(45deg);
				&::after {
					background: $color-white !important;
					top: 0;
					transform: rotate(-90deg);
				}
			}
		}
		> div {
			background: $color-primary;
			height: 2px;
			margin-top: $spacer * -0.5;
			position: relative;
			transition: 0.25s;
			width: 60px;
			&::after {
				content: '';
				background: $color-primary;
				display: block;
				height: 2px;
				position: absolute;
				top: 12px;
				left: 0;
				transition: 0.25s;
				width: 60px;
			}

			.main-header.is-scrolling & {
				background: $color-primary;
				&::after {
					background: $color-primary;
				}
			}

			body.home-page & {
				background: $color-white;
				&::after {
					background: $color-white;
				}
			}
		}
	}

	&__logo {
		animation: fade-in-down-center 0.5s 0.3s ease forwards;
		opacity: 0;
		left: 50%;
		top: 50%;

		svg {
			height: 57px;
			transition: 0.25s;
			.st0 {
				fill: $color-primary;
				transition: 0.25s;

				.main-header.is-scrolling & {
					fill: $color-primary;
				}

				body.home-page & {
					fill: $color-secondary;
				}
			}
		}
	}

	&__tickets {
		animation: fade-in-down 0.5s 0.3s ease forwards;
		opacity: 0;
		position: relative;
		text-decoration: none;
		transform: translateY(-100%);
		z-index: 100;

		&.dropdown-open {
			svg .st0 {
				fill: $color-white;
			}
		}

		svg {
			transform: rotate(25deg);

			.st0 {
				fill: $color-primary;
				transition: 0.25s;

				.main-header.is-scrolling & {
					fill: $color-primary;
				}

				body.home-page & {
					fill: $color-white;
				}
			}
		}

		&:hover,
		&:focus {
			svg .st0 {
				fill: $color-secondary !important;
			}
		}
	}

	&__off-canvas {
		background: rgba($color: $color-black, $alpha: 0.9);
		font-family: $font-oswald;
		height: 100%;
		opacity: 0;
		overflow-y: auto;
		padding: 92px $spacer * 2 $spacer * 2 $spacer * 2;
		pointer-events: none;
		position: fixed;
		top: 0;
		left: 0;
		transition: 0.25s;
		width: 100%;
		z-index: 99;
		&.is-open {
			opacity: 1;
			pointer-events: auto;
		}
		&-inner {
			max-height: 100%;
			max-width: 1200px;
			margin: 0 auto;
			width: 100%;
		}
	}

	&__label {
		border-bottom: 1px solid $color-secondary;
		display: block;
		font-size: 2.25rem;
		font-weight: 200;
		letter-spacing: 0.5rem;
		padding: 0 0 $spacer * 1.5 0;
		text-transform: uppercase;
	}

	&__level-1 {
		padding-top: $spacer * 1.5;
	}

	&__item--level-1 {
		padding: $spacer * 1.5 0;
		text-transform: uppercase;

		&.is-active {
			.main-header__url--level-1 {
				color: $color-secondary;

				&:hover,
				&:focus {
					cursor: initial;
					transform: none;
				}
			}
		}
	}

	&__url--level-1 {
		color: $color-white;
		display: inline-block;
		font-size: 1rem;
		letter-spacing: 0.5rem;
		text-decoration: none;
		transition: 0.25s;
		&:hover,
		&:focus {
			color: $color-secondary;
			transform: translateY(#{-$spacer * 0.25});
		}
	}

	&__social-list {
		margin-top: $spacer * 1.5;
		margin-bottom: $spacer * 3;

		&-item {
			margin: 0 $spacer * 0.5;
		}

		&-url {
			color: $color-white;
			display: inline-block;
			font-size: 20px;
			text-decoration: none;
			transition: 0.25s;
			&:hover,
			&:focus {
				color: $color-secondary;
				transform: translateY(#{-$spacer * 0.25});
			}
		}
	}

	@media (min-width: $breakpoint-large) {
		&__url--level-1 {
			font-size: 1.75rem;
		}
	}

	@media screen and (min-width: 1281px) {
		body.home-page & {
			background: transparent !important;
			border-color: transparent !important;
		}

		body.solid-color-nav & {
			background: $color-white !important;
			border-color: $color-border !important;
			transition: 0.25s;
		}

		body.hype-video-playing & {
			opacity: 0;
			pointer-events: none;
			transition: 0.25s;
		}

		&__toggle {
			> div {
				background: $color-primary !important;

				&::after {
					background: $color-primary !important;
				}
			}
			body:not(.solid-color-nav):not(.common-page) & {
				> div {
					background: $color-white !important;

					&::after {
						background: $color-white !important;
					}
				}
			}
		}

		&__logo {
			svg .st0 {
				fill: $color-primary !important;
			}

			body:not(.solid-color-nav):not(.common-page) & {
				svg .st0 {
					fill: $color-secondary !important;
				}
			}
			body:not(.pp-viewing-intro) & {
				svg {
					height: 64px;
				}
			}

			svg {
				height: 92px;
			}
		}

		&__tickets {
			svg .st0 {
				fill: $color-primary !important;
			}
			body:not(.solid-color-nav):not(.common-page) & {
				svg .st0 {
					fill: $color-white !important;
				}
			}
		}
	}
}
</style>
